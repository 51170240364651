<template>
    <BasePage>
        <div class="container text-center">
          <h1 class="h1 mt-8">Christmas orders are now closed.</h1>
          <p>We do have a number of products still available in store, please feel free to call to enquire availability of anything specific on 01225 313987.<br/>Goods will be sold on a strictly first come first served basis & we are now unable to reserve anything.</p>
          <p>Merry Christmas<br/>
          The Larkhall Butchers Team</p>
        </div>
    </BasePage>
</template>

<script>
document.title = "Christmas Orders | Larkhall Butchers"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'HomePage',
    components: {
        BasePage,
    },
    data() {
        return {
            products: []
        }
    },
};
</script>
